<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">

            <div class="header_section d-block padd-30 pt-0 pb-0">
              <div class="w-100 t-wrap">
                <h4 class="menu_header">New Category</h4>
              </div>
              <div class="back-btn">
                <router-link :to="{ name: 'categories' }"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                </router-link>
                <span>{{ title }}</span>
              </div>

            </div>

            <div class="create_items_section">
              <form enctype="multipart/form-data" @submit.prevent="createCategory">
                <div class="row">
                  <div class="col-md-6 bd-right pad-lr-0">
                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <label>Title</label>
                        <div>
                          <input v-model="title" @blur="v$.title.$touch" class="form-control" />
                        </div>
                        <div class="input_error" v-if="v$.title.$error">Title is required.</div>
                      </div>
                      <div class="form-group">
                        <label>Description(optional)</label>
                        <div>
                          <editor v-model="description" :init="{
                            height: 300,
                            branding: false
                          }" />
                        </div>
                      </div>
                    </div>
                    <!-- END Title and Desc-->

                    <div id="foodie-loading-cover" class="pl-5 pr-4 pt-4 mb-4">
                      <span>
                        <label data-baseweb="form-control-label">Photo</label>
                      </span>
                      <div data-baseweb="form-control" class="al dw">
                        <div class="image_div">
                          <div class="img_preview" style="">
                            <div tabindex="0"
                              style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                              <img class="image_responsive" v-if="images" :src="imageUrls[imageindex]" />
                            </div>
                            

                          </div>
                          <div class="image_content">
                            <p>File requirement: JPG, PNG, GIF, or WEBP up to 3MB.
                              Minimum pixels required: 320 for width and height.</p>
                            
                            <div class="image_button">
                              <div v-if="imageUrls.length > 0"  :class="['add_image_btn_grp', {'item_btn_add_grp': images}, 'mt-1']">
                                <div class="btn_plus_icon">
                                  <i class="fa fa-minus" style="font-size:24px" @click="removeCatImage()"></i>
                                </div>
                                <div class="btn_url">
                                  <span>Remove Image</span>
                                </div>
                              </div> 

                              <div :class="['add_image_btn_grp', {'item_btn_add_grp': images}, 'mt-2']">
                                <div class="btn_plus_icon">
                                  <input id='fileid' type='file'  @change="uploadMultipleImages($event, 2484, 1380)"  hidden/>
                                  <i class="fa fa-plus" style="font-size:24px" @click="openFileUploadDialog()"></i>
                                </div>
                                <div class="btn_url">
                                  <span>Add from URL</span>
                                </div>
                              </div> 
                                
                            </div>
                          </div>
                        </div>
                        <div class="row mt-4" v-if="imageUrls.length > 1">
                          <div class="col-md-3"  v-for="(img,imgindex) in imageUrls" :key="imgindex" @click="changeImage(imgindex)">
                            <img class="image_responsive"  :src="img" />
                            <!-- <div
                              class="col-md-3 mr-1 contain_image_section" 
                              v-for="(img,imgindex) in imageUrls" :key="imgindex" 
                              :style="{'background-image': 'url(' + imageUrls[imgindex] + ')'}"
                            >                          
                            </div> -->
                          </div>
                          
                        </div>
                        
                        
                      </div>
                      <div id="foodie-loader"> </div>
                    </div>


                  </div>

                  <!--Right Col-->
                  <div class="col-md-6 pad-l-0">
                    <div class="pl-4 pr-5 bd-b">
                      <h2 class="section-title">Item Options</h2>
                      <div class="form-group col-md-7 pad-l-0">
                        <label>Are Items Drinks ? </label>

                        <label style="margin-left:50px;" class="switch">
                          <input type="checkbox" v-model="isDrink" :true-value="true" :false-value="false" />
                          <span class="slider round"></span>
                        </label>

                      </div>
                    </div>

                    <div class="pl-4 pr-5 pt-4">
                      <div class="form-group">
                        <h2 class="section-title">Menus for Category to Appear In</h2>
                        <div>
                          <label v-for="(menu, index) in menus" :key="index" class="radio-inline">
                            <input type="checkbox" :value="menu.title" v-model="foodiemenu" @blur="v$.foodiemenu.$touch" />
                            {{ menu.title }} &nbsp;</label>
                        </div>
                        <div class="input_error" v-if="v$.foodiemenu.$error">*Menu is required.</div>
                      </div>

                    </div>
                  </div>





                  <!--  -->
                  <!-- <div class="form-group">
                      <label>Third parties To exclude</label>
                      <div>
                        <Multiselect v-model="thirdparties" mode="tags" placeholder="Select Third parties To exclude" track-by="name" label="name" :close-on-select="true" :searchable="true" :options="coptions">
                          <template v-slot:tag="{ option, handleTagRemove, disabled }">
                            <div class="multiselect-tag is-user">
                              {{ option.name }}
                              <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                <span class="multiselect-tag-remove-icon"></span>
                              </span>
                            </div>
                          </template>
                        </Multiselect>
                      </div>
                    </div> -->

                  <!--  -->
                </div>

                <div class="row bt-b">
                  <div class="col-md-12">
                    <div class="form-group ml-2r mt-3">
                      <button type="submit" class="btn btn-orange">
                        <span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Multiselect from '@vueform/multiselect';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';

const toast = useToast();

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {
    // Multiselect
    Editor
  },
  setup() {
    return { v$: useVuelidate() }
  },
  created: async function () {
    this.getMenus();
    this.getThirdParties();
  },
  data() {
    return {
      title: '',
      foodiemenu: [],
      menus: [],
      file: '',
      isDrink: false,
      description: '',
      spinner: false,
      searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving...",
      imageUrl: '',
      imageUrls: [],
      images: null,
      coptions: [],
      thirdparties: [],
      imageindex: 0
    };
  },
  validations() {
    return {
      title: { required },
      foodiemenu: { required }
    }
  },
  methods: {
    changeImage(index){
      this.imageindex = index;
    },
    removeCatImage(){
      // alert(this.imageUrls[this.imageindex])
      this.imageUrls = this.imageUrls.filter((x) => {
          return  x != this.imageUrls[this.imageindex];
      });
      this.imageindex = 0;
    },
    async createCategory() {
      this.spinner = true;
      const isFormCorrect = await this.v$.$validate()
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (!isFormCorrect) {
        this.spinner = false;
        return
      }

      


      const postData = {
        "merchant_id": parseInt(this.merchantID),
        "title": this.title,
        // "image_url": this.imageUrls,
        "image_urls": this.imageUrls,
        "is_drink": this.isDrink,
        "menu_names": this.foodiemenu,
        "description": this.description
        // "channels": this.thirdparties
      }
      const jsonParseData = JSON.stringify(postData)
      const url = this.baseUrl + '/menu_category'
      this.axios.post(url, jsonParseData, this.apiRequestConfig()).then((response) => {
        if (response.status === 201) {
          toast.success("Category created successfully");
          this.spinner = false;
          this.$router.push({
            path: 'categories'
          })
        }
      })
        .catch((error) => {
          this.spinner = false;
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        })
      return;
    },
    async getThirdParties() {
      const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
      this.axios.get(url, this.apiRequestConfig()).then((response) => {
        if (response.status === 200) {
          const channels = response.data.data
          for (let c = 0; c < channels.length; c++) {
            this.coptions.push({
              "value": channels[c].id,
              "name": channels[c].channel_name
            })
          }
        }
      });
    },
    async getMenus() {
      const url = this.baseUrl + "/menu?merchant_id=" + this.merchantID;
      this.axios.get(url, this.apiRequestConfig()).then((response) => {
        this.menus = response.data.data;
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        } else {
          if (error.response) {
            toast.error('Code: ' + error.response.status + ', message: ' + error.response.data.message);
          }
        }

      });
    }

  },
  mixins: [ReusableFunctionMixins, ReusableDataMixins]

};
</script>
